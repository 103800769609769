<template>
  <li
    class="carousel__slide"
    :class="{
      'carousel__slide--active': isActive(),
      'carousel__slide--visible': isVisible(),
      'carousel__slide--prev': isPrev(),
      'carousel__slide--next': isNext(),
    }"
    :style="slideStyle"
  >
    <slot name="default" />
  </li>
</template>

<script>
import { inject, ref, computed, watch, reactive } from 'vue'
import { defaultConfigs } from './defaults'
export default {
  name: 'CarouselSlide',
  props: {
    index: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const config = inject('config', reactive({ ...defaultConfigs }))
    const slidesBuffer = inject('slidesBuffer', ref([]))
    const currentSlide = inject('currentSlide', ref(0))
    const slidesToScroll = inject('slidesToScroll', ref(0))
    const wrapOrder = ref(props.index)

    if (config.wrapAround) {
      updateOrder()
      watch(slidesBuffer, updateOrder)
    }

    function updateOrder() {
      wrapOrder.value = slidesBuffer.value.indexOf(props.index)
    }

    const slideStyle = computed(() => {
      const items = config.itemsToShow
      const width = `${(1 / items) * 100}%`
      return {
        width,
        order: wrapOrder.value.toString(),
      }
    })

    const isActive = () => props.index === currentSlide.value
    const isVisible = () => {
      const min = Math.ceil(slidesToScroll.value)
      const max = Math.floor(slidesToScroll.value + config.itemsToShow)
      const current = slidesBuffer.value.slice(min, max)

      return current.includes(props.index)
    }

    const isPrev = () => props.index === slidesBuffer.value[Math.ceil(slidesToScroll.value) - 1]

    const isNext = () => props.index === slidesBuffer.value[Math.floor(slidesToScroll.value + config.itemsToShow)]

    return { slideStyle, isActive, isVisible, isPrev, isNext }
  },
}
</script>
<style lang="scss" scoped>
.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; opacity: 0; transition: opacity; z-index: 0;
  &.carousel__slide--active.carousel__slide--visible {opacity: 1; z-index: 1;}
}
</style>
